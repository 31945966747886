


































import { round } from "lodash";
import Vue from "vue";
import * as Helpers from '../services/helpers';
import { dataURL_to_mimeType } from '../../../shared/helpers';
import conf from '../conf'

const default_acceptedMimeTypes = conf.fileUpload_default_acceptedMimeTypes

const formatMB = (n: number) => {
    const n_ = n / 1024 / 1024;
    return "" + round(n_, n_ >= 10 ? 0 : 1) + "MB";
}


export default Vue.extend({
    props: ['value', 'name', 'opts'],
    data() {
        return {
            validity: { [this.name]: {} },
            val: this.value,
            error: undefined,
        };
    },
    computed: {
       acceptedMimeTypes() {
           return this.opts.acceptedMimeTypes || default_acceptedMimeTypes
       },
       maxSize() {
           return this.opts.maxlength && this.opts.maxlength / 1.33 /* for Base64 overhead */
       },
       maxSizeFormatted() {
            return this.maxSize && formatMB(this.maxSize)
       },
       mimeType() {
            return dataURL_to_mimeType(this.val)
       },
       is_image() {
           return this.mimeType?.match(/^image\//)
       },
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit('input', val);
        },
    },
    methods: {
        async onFileUploaded(file) {
            if (!this.acceptedMimeTypes.includes(file.type)) {
                this.val = ''
                this.error = { mimeType: file.type }
            } else if (this.maxSize && file.size > this.maxSize) {
                this.val = ''
                this.error = { size: file.size }
            } else {
                this.val = await Helpers.fileReader('readAsDataURL', file)
                this.error = undefined
            }
        },
        window_open() {
            window.open(this.val)
        }
    },
});
